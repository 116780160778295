.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 80px;

  @media (max-width: 600px) {
    height: auto;
    padding: 1.5rem 2rem;
    gap: 1.5rem;
  }

  .logo img {
    width: 18rem;
    @media (max-width: 600px) {
      width: 15rem;
    }
  }

  .account-btn {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #777777;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    span {
      color: #fff;
      font-weight: 500;
    }
  }
}
