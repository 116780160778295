@import '../../index.scss';

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #1e2328;

  h3 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .close {
    cursor: pointer;
  }
}

%base-styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-background);
  border-radius: 20px;
  max-width: 480px;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 90%;
  }

  .modal-header_title {
    font-size: 2.4rem;
    color: var(--text-primary);
  }
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  z-index: 100;
}

.transaction_modal {
  @extend %base-styles;
  padding: 2em;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 50px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(100px);

  &-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      margin: 3.2rem 0 1.6rem;
    }

    .icon {
      width: 100px;
      height: 100px;
    }
  }
}

.wallet_modal {
  @extend %base-styles;
  padding: 1.5em;

  .flex-gap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .wallet_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 1.5em;
    margin-top: 2em;

    &-card {
      box-sizing: border-box;
      cursor: pointer;
      background: #202123;
      border: 1px solid transparent;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 2rem 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: border 300ms linear;

      &:hover {
        border: 1px solid #1d2cf3;
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
  }
}

.withdraw_modal {
  @extend %base-styles;
  max-width: 480px;
  width: 100%;
  padding: 2em;

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem;

    > div {
      background: #212224;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;

      button {
        width: 100%;
      }

      .withdraw-controls {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .restake-controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        min-height: 40px;

        button {
          outline: none;
          border: none;
          background: #1b1c1e;
          border-radius: 5px;
          width: fit-content;
          height: fit-content;
          padding: 0.4rem 0.8rem;
          cursor: pointer;
          color: #888888;

          &.active {
            color: #fff;
          }
        }
      }
    }
  }
}

.account_modal {
  @extend %base-styles;
  padding: 2em;

  .user_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    h2 {
      font-size: 2.5rem;
      font-weight: 500;
    }

    .flex {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.nowallet_modal {
  @extend %base-styles;
  padding: 2em;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
}

.referrals_modal {
  @extend %base-styles;
  padding: 2em;

  .content {
    border-bottom: 1px solid #1e2328;
    padding: 1.6rem;
    .no_referrals {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }

    .referrals_list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-height: 250px;
      overflow-y: auto;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
      }
    }
  }

  .user-referrer {
    padding-top: 1.6rem;

    button {
      font-size: 1.2rem;
      padding: 0.5rem;
    }
  }

  form {
    padding-top: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      justify-content: flex-end;
    }

    input {
      background: rgba(0, 0, 0, 0.9);
      padding: 8px 1em;
      font-size: 1.6rem;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 5px;
      color: var(--text-primary);
    }
  }
}

.deposit_modal {
  @extend %base-styles;
  padding: 2em;

  .content {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    button {
      width: 100%;
    }
  }
}
