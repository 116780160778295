%base-styles {
  outline: none;
  border: none;
  padding: 8px 1em;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
    opacity: 0.7;
  }
}

.btn-primary {
  @extend %base-styles;
  background: var(--btn-primary);
  color: var(--text-primary);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.btn-secondary {
  @extend %base-styles;
  background: transparent;
  color: #000;
  border: 1px solid #1d2cf3;
}

.btn-error {
  @extend %base-styles;
  background-color: tomato;
  color: var(--text-primary);
  box-shadow: 0px 4px 10px rgba(255, 99, 71, 0.25);
  padding: 0.5rem;
  font-size: 1.2rem;
}

.btn-primary-gradient {
  @extend %base-styles;
  background: var(--btn-primary-gradient);
  color: var(--text-primary);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}
