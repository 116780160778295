.home {
  display: grid;
  place-items: center;
  padding: 3rem 0;

  .farm {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    max-width: 560px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &_header {
      padding: 2rem 3rem;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid #1b1c1e;
      border-radius: 20px 20px 0px 0px;

      .flex {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    hr {
      border: 1px solid #1b1c1e;
    }

    &_content {
      padding: 3rem;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid #1b1c1e;
      backdrop-filter: blur(40px);
      border-radius: 0px 0px 20px 20px;
      display: flex;
      flex-direction: column;

      .flex {
        margin-bottom: 2rem;

        p,
        b {
          font-size: 1.6rem;
        }
      }
    }

    .rewards {
      section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;

        button {
          padding: 6px 1em;
        }
      }
    }

    .form_container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .form_input {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      label {
        font-size: 1.4rem;
      }

      input {
        background: rgba(0, 0, 0, 0.9);
        padding: 8px 1em;
        font-size: 1.6rem;
        border: none;
        outline: none;
        width: 100%;
        border-radius: 5px;
        color: var(--text-primary);
      }

      .deposit_container {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        &-dropdown {
          position: relative;
          background: #1b1c1e;
          border-radius: 30px;
          padding: 0.8rem 1.6rem;

          &.active {
            border-radius: 10px 10px 0 0;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
          }

          .dropdown-header {
            font-size: 1.6rem;
            color: #fff;
            cursor: pointer;

            &.active {
              svg {
                transform: rotate(0deg);
              }
            }

            svg {
              transform: rotate(180deg);
              transition: transform 200ms linear;
              margin-left: 10px;
            }
          }

          .dropdown-list {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: #1b1c1e;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 10px 10px;
            display: flex;
            flex-direction: column;
            max-height: 150px;
            overflow-y: auto;
            z-index: 1;

            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              background: #171717;
              box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 20px;
            }
            &::-webkit-scrollbar-thumb {
              background: #212224;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 20px;
            }

            > P {
              color: #888888;
              font-size: 1.4rem;
              white-space: nowrap;
              padding: 0.8rem 1.6rem;
              cursor: pointer;
              transition: color 200ms linear;

              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .staking_wrapper {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;

      > div {
        display: flex;
        border-radius: 100px;
        background: transparent;
        border: 1px solid #1b1c1e;

        button {
          padding: 8px 1em;
          background-color: transparent;
          border-radius: 100px;
          border: none;
          outline: none;
          color: #fff;
          cursor: pointer;
          transition: background-color 250ms linear;

          &.active {
            background-color: #1d2cf3;
          }
        }
      }
    }

    &-card {
      display: flex;
      flex-direction: column;
      gap: 1em;

      &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form_input {
          align-items: flex-end;
        }
      }
    }
  }
}

.farm_stats {
  &-dropdown {
    position: relative;
    background: #1b1c1e;
    border-radius: 30px;
    padding: 0.8rem 1.6rem;

    &.active {
      border-radius: 10px 10px 0 0;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .dropdown-header {
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;

      &.active {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        transform: rotate(180deg);
        transition: transform 200ms linear;
        margin-left: 10px;
      }
    }

    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: #1b1c1e;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-direction: column;
      max-height: 150px;
      overflow-y: auto;
      z-index: 1;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #171717;
        box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: #212224;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }

      > P {
        color: #888888;
        font-size: 1.4rem;
        white-space: nowrap;
        padding: 0.8rem 1.6rem;
        cursor: pointer;
        transition: color 200ms linear;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
